import { useContext, useEffect, useState } from "react";
import Header from "../../components/RHSC/dev/Header";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { fetchConversations } from "./apis";
import { AlertContext } from "../../contexts/alert/AlertContext";
import Loader from "../../components/RHSC/Loader";
import { Button } from "@patternfly/react-core";
import { ArrowLeftIcon } from "@patternfly/react-icons";
import moment from "moment";
import { getDevConversationId, updateDevLastRHSCPageVisit } from "../../utils";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";

const Conversations = () => {
  const history = useHistory();
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuthContext();
  const alert = useContext(AlertContext);

  const getParams = () => {
    let params = new URLSearchParams();
    params.append("userEmail", user?.email);
    params.append("recentConversationsCount", "100");

    return params;
  };

  useEffect(() => {
    updateDevLastRHSCPageVisit("/rhsc-assistant-dev/conversations");
    setIsLoading(true);
    fetchConversations({
      endpoint: "/conversations",
      params: getParams(),
      successCallback: (data) => {
        setConversations(data);
        setIsLoading(false);
      },
      failureCallback: ({ response }) => {
        alert.addAlert(
          response?.status === 500
            ? "The backend service is down"
            : response?.data || "Something went wrong",
          "danger"
        );
        setIsLoading(false);
      },
    });
  }, []);

  const groupConversationsByCategory = (conversations) => {
    const categories = {
      Today: [],
      Yesterday: [],
      "Previous 7 Days": [],
      "Previous 30 Days": [],
      Older: [],
    };

    conversations.forEach((conversation) => {
      const createdAt = moment(conversation.createdAt);
      const today = moment();
      const yesterday = moment().subtract(1, "days");
      const sevenDaysAgo = moment().subtract(7, "days");
      const thirtyDaysAgo = moment().subtract(30, "days");

      if (createdAt.isSame(today, "day")) {
        categories["Today"].push(conversation);
      } else if (createdAt.isSame(yesterday, "day")) {
        categories["Yesterday"].push(conversation);
      } else if (createdAt.isAfter(sevenDaysAgo)) {
        categories["Previous 7 Days"].push(conversation);
      } else if (createdAt.isAfter(thirtyDaysAgo)) {
        categories["Previous 30 Days"].push(conversation);
      } else {
        categories["Older"].push(conversation);
      }
    });

    return categories;
  };

  const groupedConversations = groupConversationsByCategory(conversations);

  return (
    <div className="rhsc-assistant">
      <Header />
      <div className="rhsc-assistant-conversations">
        <div className="conversations-header">
          <p className="conversations-header-text">Past Conversations</p>
          <Button
            className="go-back-button"
            variant="link"
            icon={<ArrowLeftIcon />}
            iconPosition="start"
            onClick={() => {
              const conversationId = getDevConversationId();

              if (conversationId) {
                history.push(`/rhsc-assistant-dev/conversations/${conversationId}`);
              } else {
                history.push("/rhsc-assistant-dev");
              }
            }}
          >
            Back
          </Button>
        </div>
        {isLoading ? (
          <Loader />
        ) : conversations.length ? (
          Object.keys(groupedConversations).map((date) =>
            groupedConversations[date].length ? (
              <div key={date} className="conversation-group">
                <p className="conversation-time">{date}</p>
                {groupedConversations[date].map(
                  ({ conversationName, conversationID }) => (
                    <div
                      key={conversationID}
                      className="conversation-item"
                      onClick={() =>
                        history.push(
                          `/rhsc-assistant-dev/conversations/${conversationID}`
                        )
                      }
                    >
                      {conversationName}
                    </div>
                  )
                )}
              </div>
            ) : (
              ""
            )
          )
        ) : (
          <div className="no-conversations-found">No conversation found 😅</div>
        )}
      </div>
    </div>
  );
};

export default Conversations;
