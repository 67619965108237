import { ENV } from "../constants/env-constants";
import { getProcessType } from "../utils";
import { getEnvName } from "../utils/env-utils";

enum API_MODULE {
  PCF = "pcf",
  EVS = "evs",
  PA_UTILS = "pa-utils",
  DINNER_PLAN = "dinnerPlan",
  SHADOWBOT = "shadowbot",
  ACCOUNT_PLANNING_PA = "account-planning-pa",
  MANAGES_SERVICES = "managedservices",
  RHSC_ASSISTANT = "rhsc-assistant",
  STREAMING = "streaming",
  RHSC_ASSISTANT_DEV = "rhsc-assistant-dev",
  STREAMING_DEV = "streaming-dev",
}

const CURRENT_PROCESS = getProcessType();

const ENV_TO_BASE_URL = {
  [ENV.DEV]: {
    [API_MODULE.PCF]: "https://pca-io-7nj5mxcp6a-uc.a.run.app/pca",
    [API_MODULE.EVS]: "https://evs-io-7nj5mxcp6a-uc.a.run.app",
    [API_MODULE.PA_UTILS]: "https://pa-utils-7nj5mxcp6a-ew.a.run.app/pa-utils",
    [API_MODULE.DINNER_PLAN]: "https://evs-io-7nj5mxcp6a-uc.a.run.app",
    [API_MODULE.SHADOWBOT]:
      "https://shadowbot-io-backend-dmmhhew32q-uk.a.run.app/shadowbot",
    [API_MODULE.ACCOUNT_PLANNING_PA]:
      "https://account-planning-pa-io-7nj5mxcp6a-uc.a.run.app",
    [API_MODULE.MANAGES_SERVICES]: "http://localhost:8081",
    [API_MODULE.RHSC_ASSISTANT]:
      "https://api.enterprise.preprod.redhat.com/retriever/rhsc",
    [API_MODULE.STREAMING]:
      "https://api.enterprise.preprod.redhat.com/retriever/stream",
    [API_MODULE.RHSC_ASSISTANT_DEV]:
      "https://api.enterprise.preprod.redhat.com/retriever/rhsc",
    [API_MODULE.STREAMING_DEV]:
      "https://api.enterprise.preprod.redhat.com/retriever/stream",
  },
  [ENV.STAGE]: {
    [API_MODULE.PCF]: "https://pca-io-7nj5mxcp6a-uc.a.run.app/pca",
    [API_MODULE.EVS]: "https://evs-io-7nj5mxcp6a-uc.a.run.app",
    [API_MODULE.PA_UTILS]: "https://pa-utils-7nj5mxcp6a-ew.a.run.app/pa-utils",
    [API_MODULE.DINNER_PLAN]: "https://evs-io-7nj5mxcp6a-uc.a.run.app",
    [API_MODULE.SHADOWBOT]:
      "https://shadowbot-io-backend-dmmhhew32q-uk.a.run.app/shadowbot",
    [API_MODULE.ACCOUNT_PLANNING_PA]:
      "https://account-planning-pa-io-7nj5mxcp6a-uc.a.run.app",
    [API_MODULE.MANAGES_SERVICES]:
      "https://managed-services-io-7nj5mxcp6a-ew.a.run.app",
    [API_MODULE.RHSC_ASSISTANT]:
      "https://api.enterprise.preprod.redhat.com/retriever/rhsc",
    [API_MODULE.STREAMING]:
      "https://api.enterprise.preprod.redhat.com/retriever/stream",
    [API_MODULE.RHSC_ASSISTANT_DEV]:
      "https://api.enterprise.preprod.redhat.com/retriever/rhsc",
    [API_MODULE.STREAMING_DEV]:
      "https://api.enterprise.preprod.redhat.com/retriever/stream",
  },
  [ENV.PROD]: {
    [API_MODULE.PCF]: "https://pca-io-xmcuowhipq-uc.a.run.app/pca",
    [API_MODULE.EVS]: "",
    [API_MODULE.PA_UTILS]:
      "https://pa-utils-io-xmcuowhipq-uc.a.run.app/pa-utils",
    [API_MODULE.SHADOWBOT]:
      "https://shadowbot-io-backend-z7d65mvkuq-ey.a.run.app/shadowbot",
    [API_MODULE.ACCOUNT_PLANNING_PA]: "",
    [API_MODULE.MANAGES_SERVICES]: "",
    [API_MODULE.RHSC_ASSISTANT]:
      "https://api.enterprise.redhat.com/retriever/rhsc",
    [API_MODULE.STREAMING]:
      "https://api.enterprise.redhat.com/retriever/stream",
  },
};

const getBaseURL = (process: string) => {
  const CURRENT_ENV = getEnvName();
  return ENV_TO_BASE_URL[CURRENT_ENV][process];
};

const CURRENT_PROCESS_API_BASE_URL = getBaseURL(CURRENT_PROCESS);
const PCA_API_BASE_URL = getBaseURL(API_MODULE.PCF);
const EVS_API_BASE_URL = getBaseURL(API_MODULE.EVS);
const UTILS_API_BASE_URL = getBaseURL(API_MODULE.PA_UTILS);
const DINNER_PLAN_API_BASE_URL = getBaseURL(API_MODULE.DINNER_PLAN);
const MANAGES_SERVICES_API_BASE_URL = getBaseURL(API_MODULE.MANAGES_SERVICES);

const SHADOWBOT_API_BASE_URL = getBaseURL(API_MODULE.SHADOWBOT);
const ACCOUNT_PLANNING_PA_API_BASE_URL = getBaseURL(
  API_MODULE.ACCOUNT_PLANNING_PA
);
const RHSC_ASSISTANT_API_BASE_URL = getBaseURL(API_MODULE.RHSC_ASSISTANT);
const STREAMING_API_BASE_URL = getBaseURL(API_MODULE.STREAMING);
const RHSC_ASSISTANT_DEV_API_BASE_URL = getBaseURL(API_MODULE.RHSC_ASSISTANT);
const STREAMING_DEV_API_BASE_URL = getBaseURL(API_MODULE.STREAMING);
const DINNER_RECIPE_BASE_URL = "https://forkify-api.herokuapp.com/api";

const pcaApiEndpoints = {
  enrichProject: "/enrich_project",
  enrichPca: "/enrich_pca",
  pca: "/project_change_approval",
};

const evsApiEndpoints = {
  enrichEvs: "/evsEnrich",
  evs: "/evfApproval",
};

const utilsApiEndpoints = {
  processComments: "/process-comments",
  roverEmail: "/rover-people",
  users: "/users",
  authorize: "/comments/notifications/authorize",
  register: "/comments/notifications/register",
  notifications: "/comments/notifications",
  read: "/comments/notifications/read",
  sbrTeams: "/hydra/sbrs",
};

const dinnerPlanApiEndpoints = {
  dinnerPlan: "/dinnerPlan",
};

const accountPlanningEndpoints = {
  enrichAccountPlan: "/enrich_account_plan",
  accountPlanning: "/accountPlanning",
};

const shadowbotEndpoints = {
  token: "/users/token",
};

const managedServicesEndpoints = {
  managedServices: "/managedservices",
};

export {
  pcaApiEndpoints,
  evsApiEndpoints,
  utilsApiEndpoints,
  dinnerPlanApiEndpoints,
  shadowbotEndpoints,
  accountPlanningEndpoints,
  managedServicesEndpoints,
  CURRENT_PROCESS_API_BASE_URL as apiBaseUrl,
  getBaseURL,
  PCA_API_BASE_URL,
  EVS_API_BASE_URL,
  UTILS_API_BASE_URL,
  DINNER_PLAN_API_BASE_URL,
  SHADOWBOT_API_BASE_URL,
  ACCOUNT_PLANNING_PA_API_BASE_URL,
  DINNER_RECIPE_BASE_URL,
  MANAGES_SERVICES_API_BASE_URL,
  RHSC_ASSISTANT_API_BASE_URL,
  STREAMING_API_BASE_URL,
  RHSC_ASSISTANT_DEV_API_BASE_URL,
  STREAMING_DEV_API_BASE_URL,
};
