import apiClient from "../../apis/api-client";
import { SHADOWBOT_API_BASE_URL } from "../../apis/endpoints";
import rhscAssistantDevApiClient from "../../apis/rhsc-assistant/api-client-dev";

interface RequestProps {
  endpoint: string;
  dataToSend?: any;
  successCallback?: (data) => void;
  failureCallback?: (data) => void;
  params?: any;
}

const submitMessageFeedback = async (props: RequestProps) => {
  const { endpoint, dataToSend, successCallback, failureCallback } = props;
  const response = await rhscAssistantDevApiClient.post(endpoint, dataToSend);

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const fetchConversations = async (props: RequestProps) => {
  const { endpoint, successCallback, failureCallback, params } = props;
  const response = await rhscAssistantDevApiClient.get(endpoint, { params });

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const fetchConversationById = async (props: RequestProps) => {
  const { endpoint, successCallback, failureCallback, params } = props;
  const response = await rhscAssistantDevApiClient.get(endpoint, { params });

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const submitVoFFeedback = async (props: RequestProps) => {
  const { endpoint, dataToSend, successCallback, failureCallback } = props;
  const response = await apiClient(SHADOWBOT_API_BASE_URL).post(
    endpoint,
    dataToSend
  );

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

export {
  submitMessageFeedback,
  fetchConversations,
  fetchConversationById,
  submitVoFFeedback,
};
